@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@scrollbarWidth: 6px;
@outerTilePadding: 16px;
@wrapperMargin: 20px;
@bottomSidebarHeight: 48px;
@sidebarAtBottom: 991px;

.sidebarVbIcon {
	&:before {
		font-size: @type20;
	}
}

.videoSearchResultsRoot {
	overflow: hidden;
}

.searchContent {
	order: 1;
	position: relative;
	padding-top: 16px;
}

.scrollExpiredMsg {
	position: absolute;
	text-align: center;
	width: 100%;
	left: 0;
	padding-bottom: 20px;

	a {
		color: var(--theme-accent);
	}
}

.tilesSearchContent {
	composes: searchContent;
	overflow-y: scroll;
	width: 100%;

	@media (min-width: 1200px) {
		padding-right: calc(32px + @scrollbarWidth) !important;
		padding-left: calc(32px + @wrapperMargin - @scrollbarWidth) !important;
	}

	@media (min-width: 768px) and (max-width: 1199px) {
		padding-right: 16px !important;
		padding-left: calc(16px + @wrapperMargin - @scrollbarWidth) !important;
	}

	&:not(:last-child) {
		margin-right: calc(@wrapperMargin - @outerTilePadding) !important;
	}

	@media (max-width: @sidebarAtBottom) {
		margin-right: calc(@wrapperMargin - @outerTilePadding) !important;
		margin-left: auto !important;
		margin-bottom: @bottomSidebarHeight;
	}
}

.tableSearchContent {
	composes: searchContent;
	display: flex;
	flex-direction: column;
	margin-right: calc(@wrapperMargin) !important;
	margin-left: @wrapperMargin !important;

	&:not(:last-child) {
		margin-right: @wrapperMargin  !important;
	}

	@media (max-width: @sidebarAtBottom) {
		margin-right: @wrapperMargin  !important;
	}
}

.sidebar {
	order: 2;

	:global(.vb-icon-filter-alt) {
		font-size: 1.5rem;
		display: block;
		height: 41px;
		padding-top: 10px;
	}

	:global(.vb-icon-close) {
		font-size: 0.8rem;
	}

	&:global(.is-open:not(.force-closed)) {
		overflow-x: hidden;
	}

	&:global(.is-open.force-closed) {
		:global(.right-sidebar-content) {
			display: none;
		}
	}
}
