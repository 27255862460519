@import (reference) "~rev-less/global/mixins";

.fixed-ratio-wrapper {
	width: 100%;
	position: relative;

	.tile-item & + & {
		&:after {
			padding-top: 56.25%;
			display: block;
			content: '';
		}

		.fixed-ratio {
			&:extend(.AbsoluteFill);
			height: 100%;
			overflow: hidden;
		}
	}

	img {
		.BoxShadow(0 0 4px fade(@black, 80%));
	}
}

.fixed-ratio {
	position: relative;
	height: 0;
	padding-top: 56.26%;
	display: block;
	overflow: hidden;
	width:100%;

	/* NOTE: Firefox does not vertically center these */
	> img,
	> .item,
	> .fixed-aspect-item, /* use this going forward */
	> .vb-player {
		&:extend(.AbsoluteFill);

		max-width: 100%;
		min-width: 100%;
		min-height: 100%;
		margin: auto;
	}
}

.grid-item-list {
	&.fixed-ratio-wrapper:after {
		padding-top: 56.25%; /* 16:9 ratio */
		display: block;
		content: '';
	}

	> .fixed-ratio {
		&:extend(.AbsoluteFill);
		height: 100%;
		overflow: hidden;
		padding-top: 0;
	}
}

.dbl {
	.fixed-ratio-wrapper {
		width: 200%;
	}

	&:first-child {
		.fixed-ratio-wrapper {
			position: absolute;
			right: 0;
		}

		.overlay .glyphicons {
			margin: 18% 15% 25% 60%;
		}
	}

	&:last-child .fixed-ratio-wrapper {
		position: absolute;
		left: 0;
	}

	img {
		opacity: 1;
	}
}

/* inverted proportions for playlist next to player */
.list .playlist-container {
	width: 100%;
	position: relative;

	.file-list-wrap {
		position: relative;
		height: 0;
		padding-top: 112.25%;  /* not exactly sure why this is the correct % */
		display: block;
		overflow: hidden;
		width :100%;

		.fixed-list-ratio {
			&:extend(.AbsoluteFill);
			max-width: 100%;
			min-width: 100%;
			min-height: 100%;
			margin: auto;
		}
	}
}
