@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.inputContainer {
	composes: inputContainer from '../VbUiSingleUserSelectComponent/VbUiSingleUserSelect.Component.module.less';

	typeahead-container {
		width: calc(100% - 32px);
		z-index: 1000 !important;
		transform: none !important;
		top: 40px !important;
		left: 16px !important;
	}
}

.viewport {
	composes: viewport from '../VbUiSingleUserSelectComponent/VbUiSingleUserSelect.Component.module.less';
}

.videoListItem {
	composes: itemListItem from '../VbUiSingleUserSelectComponent/VbUiSingleUserSelect.Component.module.less';
}

.videoInfo {
	display: flex;
	margin-top: 8px;
	flex: 1 0;
	display: flex;
	flex-direction: row;
	overflow: hidden;

	span {
		margin: 2px 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: smaller;
	}

	.infoLarge {
		flex-grow: 3;
	}

	.infoTxt {
		font-size: x-small;
	}

	.infoTitle {
		font-size: smaller;
	}

	.infoBold {
		font-weight: bold;
	}
}

.videoInfoItem {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 0;
}

.videoInputContainer {
	box-shadow: inset 0 0 0 1px grey;

	button {
		border: none;
		background-color: transparent;
	}
}

.videoInputContainer:focus-within {
	border: none;
}

.videoList {
	composes: itemList from '../VbUiSingleUserSelectComponent/VbUiSingleUserSelect.Component.module.less';
	min-height: 458px;
	width: 100%;
	top: 13px;
	left: -28px;

	.viewport {
		min-height: 458px;
	}
}

.itemContainer {
	display: flex;
	border-bottom: 1px solid black;

	vb-ui-thumbnail-sheet-preview {
		flex: 0 1 auto;
		margin: 8px;
	}

	img {
		width: 110px;
		height: 75px;
		padding: 8px;
	}
}

.selectedItemContainer {
	display: flex;
	position: relative;
	border: 1px @lightBorder solid;
	border-radius:  3px;;

	button {
		border: none;
		background-color: transparent;
	}

	img {
		width: 120px;
		height: 67px;
		margin: 8px;
	}
}

.highlight {
	font-weight: bold;
	font-size: revert !important;
}

.duration {
	position: absolute;
	bottom: 13px;
	right: 5px;
	color: @white;
	background-color: black;
	padding: 1px;
	font-size: smaller;
}

.videoListButtonContainer {
	width: 100%;
	padding: 8px;
	border-bottom: 1px solid @borderColor;

	button {
		border: 1px solid @lightBorder;
	}
}

.closeBtn {
	margin: 3px 5px;
}
